import React, { useEffect, useState } from 'react'
import Cart from '../../../Shopify/Cart'
import { Link } from 'gatsby'

const ExpandableNav = React.forwardRef(
  (
    {
      data,
      animation,
      searchFunction,
      bookmarks,
      removeBookmark,
      exportBookmarks,
    },
    ref
  ) => {
    const [search, setSearch] = useState('')
    const [isCartEmpty, setIsCartEmpty] = useState(true)
    useEffect(() => {
      searchFunction(search)
    }, [search])
    useEffect(() => () => searchFunction(''), [])
    return (
      <div className="expandableNav__container" ref={ref}>
        <div
          className={
            data.keyword +
            ' expandablenav ' +
            animation +
            ((data.keyword == 'saved' && bookmarks.length == 0) ||
            (data.keyword == 'cart' && isCartEmpty)
              ? ' empty '
              : '')
          }
        >
          <div className="expandableNav_content">
            {data.keyword == 'search' && (
              <>
                <input
                  type="text"
                  placeholder="Search any product"
                  onInput={event => {
                    setSearch(event.target.value)
                  }}
                />
              </>
            )}
            {data.keyword == 'chat' && (
              <>
                <div className="row">
                  <div className="eight columns">
                    <p>
                      Inquire by email: <br />
                      <a
                        href="mailto:stellarunionllc@gmail.com?subject=Stellar Union Inquire"
                        style={{ color: '#000' }}
                      >
                        stellarunionllc@gmail.com
                      </a>
                    </p>
                  </div>
                  <div className="four columns">
                    <p>
                      Phone: <br />
                      <a href="tel:(631) 204-0315" style={{ color: '#000' }}>
                        (631) 204-0315
                      </a>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="location">
                    <p>
                      Location: <br />
                      <a href="https://goo.gl/maps/XGT36xranykUUru86">
                        25 North Sea Road Southampton, NY
                      </a>
                    </p>
                  </div>
                </div>
              </>
            )}
            {data.keyword == 'saved' && (
              <>
                <ul>
                  {bookmarks &&
                    bookmarks.map(product => {
                      return (
                        product && (
                          <li className="row">
                            <Link to={'/product/' + product.handle}>
                              <div className="three columns">
                                <img src={product.media[0].image.originalSrc} />
                              </div>
                              <div className="nine columns">
                                <p className="aktifo-medium">{product.title}</p>
                                <img
                                  onClick={() => {
                                    removeBookmark(product)
                                  }}
                                  className="close"
                                  src="/global/X.svg"
                                />
                              </div>
                            </Link>
                          </li>
                        )
                      )
                    })}
                  {(!bookmarks || bookmarks.length == 0) && (
                    <li className="aktifo-medium">Add a bookmark please</li>
                  )}
                </ul>
                <div
                  className="export aktifo-book"
                  onClick={() => {
                    exportBookmarks()
                  }}
                >
                  Export list
                </div>
              </>
            )}
            {data.keyword == 'cart' && (
              <Cart
                triggerIsEmpty={isEmpty => {
                  setIsCartEmpty(isEmpty)
                }}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
)
export default ExpandableNav
