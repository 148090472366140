import React, { useContext, useEffect } from 'react'
import { Link } from 'gatsby'

import StoreContext from '~/context/StoreContext'

const LineItem = ({ item }) => {
  const {
    removeLineItem,
    updateLineItem,
    store: { client, checkout },
  } = useContext(StoreContext)
  useEffect(() => {}, [item, checkout])

  const handleRemove = () => {
    removeLineItem(client, checkout.id, item.id)
  }
  const updateQuantity = isIncrease => {
    if (isIncrease) {
      updateLineItem(client, checkout.id, item.id, item.quantity + 1)
    } else {
      updateLineItem(client, checkout.id, item.id, item.quantity - 1)
    }
  }

  const currency = item.variant.priceV2.currencyCode === 'EUR' ? '€' : '$'
  return (
    <div className="lineitem">
      <img className="productimg" src={item.variant.image.src} />
      <div className="li_holder">
        <p className="title aktifo-book">{item.title}</p>
        <p className="quantity aktifo-medium">
          <b>Qty:</b> {item.quantity}
        </p>
      </div>
      <div className="li_holder">
        <div className="price aktifo-medium">
          {item.variant.priceV2.amount}
          {currency}
        </div>
        <img
          onClick={() => {
            handleRemove()
          }}
          className="close"
          src="/global/X.svg"
        />
      </div>
    </div>
  )
}

export default LineItem
