import React, { useEffect, useState } from 'react'

const Filter = ({ filters, setActiveFilter }) => {
  const [active, setActive] = useState(filters[0])
  useEffect(() => {
    setActiveFilter(active)
  }, [active])
  return (
    <ul className="filter__">
      {filters &&
        filters.map((filter, i) => {
          return (
            <li
              key={i}
              onClick={() => {
                setActive(filter)
              }}
              className={filter == active ? 'active' : ''}
            >
              {filter}
            </li>
          )
        })}
    </ul>
  )
}
export default Filter
