import React, { useContext, useEffect } from 'react'
import StoreContext from '~/context/StoreContext'
import LineItem from './LineItem'

const Cart = ({ triggerIsEmpty, isDesktop = true }) => {
  const {
    store: { checkout },
  } = useContext(StoreContext)

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }
  const currency = checkout.currencyCode === 'EUR' ? '€' : '$'
  useEffect(() => {
    triggerIsEmpty && triggerIsEmpty(checkout.lineItems.length === 0)
  }, [checkout])
  return (
    <div
      className={
        'cart__component ' + (checkout.lineItems.length === 0 ? ' empty ' : ' ')
      }
    >
      <ul className={'cart-items ' + (isDesktop ? '' : 'mobile')}>
        {checkout.lineItems.length > 0 &&
          checkout.lineItems.map(item => (
            <li key={item.id.toString()}>
              <LineItem key={item.id.toString()} isDesktop={true} item={item} />
            </li>
          ))}
      </ul>
      <div
        className="checkout aktifo-book"
        onClick={() => {
          checkout.lineItems.length != 0 && handleCheckout()
        }}
      >
        Checkout
      </div>
      {checkout.lineItems.length == 0 && (
        <p className="empty-message aktifo-book">The cart is empty.</p>
      )}
    </div>
  )
}

export default Cart
