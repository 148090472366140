import React, { Children, useEffect, useState } from 'react'
import ContextProvider from '~/provider/ContextProvider'
import Navigation from '../components/Global/Navigation'
import * as cookies from '../hooks/Cookies'
import GetProduct from '../hooks/Shopify/GetProducts'

const Layout = ({ children, location }) => {
  const [isDesktop, setIsDesktop] = useState()
  const [search, setSearch] = useState('')
  const [activeFilter, setActiveFilter] = useState('')
  const [openFilter, setOpenFilter] = useState(false)
  const [bookmarks, setBookmarks] = useState([])
  const [copyPopup, setCopyPopup] = useState(false)
  const [copyMessage, setCopyMessage] = useState('')
  const [isGenerated, setIsGenerated] = useState(false)
  const isProducts = location.pathname.includes('/product') ? true : false
  const products = GetProduct()
  const onResize = () => {
    setIsDesktop(window.innerWidth > 1250)
  }
  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    const myImg = document.querySelectorAll('.appear')
    let observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const element = entry.target
        if (entry.intersectionRatio > 0) {
          element.classList.add('animate')
        } else {
          element.classList.remove('animate')
        }
      })
    })
    myImg.forEach(image => {
      observer.observe(image)
    })
  }, [])
  const addBookmark = product => {
    const bookmark = [...bookmarks]
    bookmark.push(product)
    setBookmarks([...new Set(bookmark)])
    setCopyPopup(true)
    setCopyMessage('Added to bookmarks')
    setTimeout(() => {
      setCopyPopup(false)
    }, 1000)
  }
  const removeBookmark = product => {
    const bookmark = [...bookmarks]
    const index = bookmark.indexOf(product)
    if (index > -1) {
      bookmark.splice(index, 1)
    }
    setBookmarks([...new Set(bookmark)])
  }
  useEffect(() => {
    const getElementByObjectValue = (arr, value) => {
      const result = arr.filter(function (o) {
        return o.node.node.shopifyId == 'gid://shopify/Product/' + value
      })

      return result ? result[0].node.node : null
    }
    if (location.search.length > 0) {
      setIsGenerated(true)
      const params = new URLSearchParams(location.search)
      let bks = []
      params.forEach(param => {
        const bk = getElementByObjectValue(products.type, param)
        bks.push(bk)
      })
      setBookmarks(bks)
    } else {
      const bm = cookies.obtain('bookmarks')
      if (Object.keys(bm).length > 0) {
        setBookmarks(bm)
      }
    }
  }, [])
  useEffect(() => {
    cookies.store('bookmarks', bookmarks)
  }, [bookmarks])
  const exportBookmarks = () => {
    if (bookmarks.length > 0) {
      const p = new URLSearchParams()
      bookmarks.forEach(bookmark => {
        p.append(
          'bookmark',
          bookmark.shopifyId.replace('gid://shopify/Product/', '')
        )
      })
      const url = location.origin + '?' + p.toString()
      navigator.clipboard.writeText(url).then(() => {
        setCopyPopup(true)
        setCopyMessage('Coppied to clipboard')
        setTimeout(() => {
          setCopyPopup(false)
        }, 1000)
      })
    } else {
      setCopyPopup(true)
      setCopyMessage(
        'The bookmarks are empty, please add before trying to copy.'
      )
      setTimeout(() => {
        setCopyPopup(false)
      }, 3000)
    }
  }
  return (
    <ContextProvider>
      <span key={location}>
        <Navigation
          isDesktop={isDesktop}
          search={data => {
            setSearch(data)
          }}
          filter={activefilter => {
            setActiveFilter(activefilter)
          }}
          openFiltering={f => {
            setOpenFilter(f)
          }}
          hideNavBlock={isProducts}
          bookmarks={bookmarks}
          removeBookmark={removeBookmark}
          exportBookmarks={exportBookmarks}
          isProducts={isProducts}
          isGenerated={isGenerated}
        />
        <div
          className={
            (!isProducts ? ' grid ' : '') + (isDesktop ? '' : ' mobile ')
          }
        >
          {Children.map(children, child => {
            return React.cloneElement(child, {
              isDesktop: isDesktop,
              search: search,
              activeFilter: activeFilter,
              openFilters: openFilter,
              bookmark: addBookmark,
            })
          })}
        </div>
        {copyPopup && (
          <div className="copypopup aktifo-medium">{copyMessage}</div>
        )}
      </span>
    </ContextProvider>
  )
}
export default Layout
