import React, { useContext, useState, useEffect, useRef } from 'react'
import reduce from 'lodash/reduce'
import StoreContext from '~/context/StoreContext'
import ExpandableNav from './ExpandableNav'
import DelayUnmount from '../../../hooks/DelayUnmount'
import Filter from './Filter'
import { Link } from 'gatsby'
import Cart from '../../Shopify/Cart'

const useQuantity = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []
  const total = reduce(items, (acc, item) => acc + item.quantity, 0)
  return [total !== 0, total]
}
const Navigation = ({
  isDesktop,
  search,
  filter,
  openFiltering,
  hideNavBlock,
  bookmarks,
  removeBookmark,
  exportBookmarks,
  isProducts,
  isGenerated,
}) => {
  const [hasItems, quantity] = useQuantity()
  const [isOpenCart, setisOpenCart] = useState(false)
  const [isOpenContacts, setIsOpenContacts] = useState(false)
  const [openFilter, setOpenFilter] = useState(false)
  const [openExpandableNav, setOpenExpandableNav] = useState(isGenerated)
  const [dataForExpandableNav, _setDataForExpandableNav] = useState({
    keyword: 'saved',
  })
  const [hasScrolled, setHasScrolled] = useState(false)
  const [isGeneratedLink, setIsGeneratedLink] = useState(isGenerated)
  const shouldRenderChild = DelayUnmount(openExpandableNav, 500)
  const shouldRenderMobileCart = DelayUnmount(isOpenCart, 500)
  const shouldRenderMobileContacts = DelayUnmount(isOpenContacts, 500)

  const navigationRef = useRef(null)
  const mobileNavigationRef = useRef(null)

  const getCircledUnicode = number => {
    let code = 9450 // default, value is 0
    if (number > 0 && number < 21) {
      code = 9311 + number
    } else if (number >= 21 && number < 36) {
      code = 12860 + number
    } else if (number >= 36 && number < 51) {
      code = 12941 + number
    }

    return String.fromCharCode(code)
  }
  const handleScroll = event => {
    const display = event.srcElement.scrollingElement.scrollTop > 40
    setHasScrolled(display)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return function cleanup() {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (openFilter) {
      setisOpenCart(false)
    }
  }, [openFilter])

  const setDataForExpandableNav = keyword => {
    const object = {
      keyword: keyword,
    }
    if (keyword == dataForExpandableNav?.keyword && openExpandableNav) {
      setOpenExpandableNav(false)
    } else {
      setOpenExpandableNav(false)

      setTimeout(() => {
        setOpenExpandableNav(true)
      }, 1)
    }
    isGeneratedLink && setIsGeneratedLink(false)
    _setDataForExpandableNav(object)
  }
  useEffect(() => {
    openFiltering(openFilter)
  }, [openFilter])

  function useOutsideAlerter(ref) {
    useEffect(() => {
      const handleClickOutside = event => {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !event.target.classList.contains('mobile_product_cart') &&
          !event.target.classList.contains('nav__item')
        ) {
          setOpenExpandableNav(false)
          setisOpenCart(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  if (isDesktop) {
    useOutsideAlerter(navigationRef)
  } else {
    useOutsideAlerter(mobileNavigationRef)
  }

  return (
    <>
      {isDesktop && (
        <>
          <div className="nav__desktop">
            {!hideNavBlock && (
              <>
                <div className="container">
                  <div className="row navbar">
                    <div className="twelve columns">
                      <Link to={'/'}>
                        <img
                          className="logo_desk"
                          src="/client__assets/stellarunion.svg"
                        />
                      </Link>
                    </div>
                    {false && (
                      <a className="ten columns aktifo-book">'About'</a>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="nav_absolute__container">
              <ul
                className={`${
                  (!openExpandableNav && hasScrolled) ||
                  (!openExpandableNav && isProducts)
                    ? 'navigation__ boxshadow'
                    : 'navigation__'
                } ${isProducts ? 'insideProduct' : ''} ${
                  hasScrolled ? 'hasScrolled' : ''
                }`}
              >
                <li
                  className="aktifo-book nav__item"
                  onClick={() => {
                    setDataForExpandableNav('chat')
                  }}
                >
                  contact
                </li>
                <li
                  className="aktifo-book nav__item"
                  onClick={() => {
                    setDataForExpandableNav('search')
                  }}
                >
                  search
                </li>
                <li
                  className="aktifo-book nav__item"
                  onClick={() => {
                    setDataForExpandableNav('saved')
                  }}
                >
                  saved
                </li>
                <li
                  className="cart aktifo-book nav__item"
                  onClick={() => {
                    setDataForExpandableNav('cart')
                  }}
                >
                  cart {getCircledUnicode(quantity)}
                </li>
              </ul>
            </div>
            {(shouldRenderChild || isGeneratedLink) && (
              <ExpandableNav
                data={dataForExpandableNav}
                searchFunction={search}
                animation={
                  openExpandableNav || isGeneratedLink ? 'start' : 'end'
                }
                bookmarks={bookmarks}
                removeBookmark={removeBookmark}
                exportBookmarks={exportBookmarks}
                ref={navigationRef}
              />
            )}
          </div>
        </>
      )}
      {!isDesktop && !hideNavBlock && (
        <div className={'nav__mobile ' + (hasScrolled ? 'boxshadow' : null)}>
          <div className="content">
            <div className="navbtn">
              {/*<img src="/global/navbtn.svg" />*/}
              <a
                className="aktifo-book"
                onClick={() => {
                  setIsOpenContacts(!isOpenContacts)
                  setisOpenCart(false)
                }}
              >
                Contact
              </a>
            </div>
            {!hasScrolled && !openFilter && (
              <Link to={'/'}>
                <img className="logo" src="/client__assets/stellarunion.svg" />
              </Link>
            )}
            {(hasScrolled || openFilter) && (
              <div
                className="logo logo_filter"
                onClick={() => {
                  setOpenFilter(!openFilter)
                  setIsOpenContacts(false)
                }}
              >
                Filters
                <img
                  src="/global/arrow_down.svg"
                  className={openFilter ? 'rotate' : null}
                />
              </div>
            )}
            <a
              className="cart aktifo-book"
              onClick={() => {
                setDataForExpandableNav('cart')
                setisOpenCart(!isOpenCart)
              }}
            >
              cart {getCircledUnicode(quantity)}
            </a>
          </div>
        </div>
      )}
      {!isDesktop && hideNavBlock && (
        <a
          className="mobile_product_cart aktifo-book"
          onClick={() => {
            setDataForExpandableNav('cart')
            setisOpenCart(!isOpenCart)
          }}
        >
          cart {getCircledUnicode(quantity)}
        </a>
      )}
      {!hideNavBlock && (
        <Filter
          filters={['everything', 'featured', 'price reduced', 'just in']}
          setActiveFilter={activefilter => {
            filter(activefilter)
          }}
        />
      )}

      {shouldRenderMobileCart && !isDesktop && (
        <div
          className={'mobile-cart ' + (isOpenCart ? 'start' : 'end')}
          ref={mobileNavigationRef}
        >
          <Cart isDesktop={isDesktop} />
        </div>
      )}
      {shouldRenderMobileContacts && !isDesktop && (
        <div
          className={'mobile-contacts ' + (isOpenContacts ? 'start' : 'end')}
        >
          <div className="row">
            <div className="eight columns">
              <p>
                Inquire by email: <br />
                <a
                  href="mailto:stellarunionllc@gmail.com?subject=Stellar Union Inquire"
                  style={{ color: '#000' }}
                >
                  stellarunionllc@gmail.com
                </a>
              </p>
            </div>
            <div className="four columns">
              <p>
                Phone: <br />
                <a href="tel:(631) 204-0315" style={{ color: '#000' }}>
                  (631) 204-0315
                </a>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="location">
              <p>
                Location: <br />
                <a
                  href="https://goo.gl/maps/XGT36xranykUUru86"
                  style={{ color: '#000' }}
                >
                  25 North Sea Road Southampton, NY
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default Navigation
