export const store = (name, object, howLong = null) => {
  // howLong is in days
  remove(name)
  if (howLong) {
    const d = new Date()
    d.setDate(d.getDate() + howLong)
    const obj = {
      expiry: d,
      value: object,
    }
    window.localStorage.setItem(name, JSON.stringify(obj))
  } else {
    window.localStorage.setItem(name, JSON.stringify(object))
  }
}
export const obtain = name => {
  const value = JSON.parse(window.localStorage.getItem(name) || '{}')
  return value
}
export const remove = name => {
  window.localStorage.removeItem(name)
}
